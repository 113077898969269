var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('r-page-modal',{scopedSlots:_vm._u([{key:"page-header",fn:function(){return [_c('h3',{staticClass:"page-subtitle has-text-weight-black"},[_vm._v("Request Time Off")])]},proxy:true},{key:"page-content",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14-px-normal",attrs:{"multipart":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"time off category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"label":"Time off category","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-select',{attrs:{"aria-role":"list","placeholder":"Select category...","expanded":true},model:{value:(_vm.data.timeoff),callback:function ($$v) {_vm.$set(_vm.data, "timeoff", $$v)},expression:"data.timeoff"}},_vm._l((_vm.timeoffList),function(option){return _c('option',{key:'employment-' + option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"label":"Start Date","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Select a date...","min-date":_vm.minDate,"max-date":_vm.maxDate},model:{value:(_vm.data.start_date),callback:function ($$v) {_vm.$set(_vm.data, "start_date", $$v)},expression:"data.start_date"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"end_date","rules":"required|date_higher_than:@start_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"label":"End Date","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Select a date...","min-date":_vm.data.start_date,"max-date":_vm.maxDate},model:{value:(_vm.data.end_date),callback:function ($$v) {_vm.$set(_vm.data, "end_date", $$v)},expression:"data.end_date"}})],1)]}}],null,true)})],1),(_vm.data.image == null)?_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"Upload File"}},[_c('b-upload',{attrs:{"accept":"image/*","drag-drop":"","expanded":"","m":""},model:{value:(_vm.data.image),callback:function ($$v) {_vm.$set(_vm.data, "image", $$v)},expression:"data.image"}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"image","size":"is-large"}})],1),_c('p',[_vm._v(" Drag image here or "),_c('span',{staticClass:"has-red-text"},[_vm._v("Browse")])])])])])],1)],1):_vm._e(),(_vm.data.image != null)?_c('div',{staticClass:"column is-4"},[_c('label',{staticClass:"label"},[_vm._v("File to upload")])]):_vm._e(),(_vm.data.image != null)?_c('div',{staticClass:"column is-4"},[_c('div',{staticClass:"tag is-primary"},[_c('span',{staticClass:"file-tag"},[_vm._v(" "+_vm._s(_vm.data.image.name)+" ")]),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDropFile()}}})])]):_vm._e(),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"request reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"label":"Request Reason","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"type":"textarea","placeholder":"Please write your message"},model:{value:(_vm.data.request_reason),callback:function ($$v) {_vm.$set(_vm.data, "request_reason", $$v)},expression:"data.request_reason"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('b-button',{attrs:{"native-type":"submit","expanded":"","loading":_vm.isLoading,"type":"is-primary"}},[_vm._v(" Request Time Off ")])],1)])])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }