<template>
  <r-page-modal>
    <template #page-header>
      <h3 class="page-subtitle has-text-weight-black">Request Time Off</h3>
    </template>
    <template #page-content>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(submit)"
          class="is-label-14-px-normal"
          multipart
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider
                name="time off category"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Time off category"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-select
                    aria-role="list"
                    placeholder="Select category..."
                    v-model="data.timeoff"
                    :expanded="true"
                  >
                    <option
                      v-for="option in timeoffList"
                      :value="option.id"
                      :key="'employment-' + option.id"
                    >
                      {{ option.name }}
                    </option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="start_date"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Start Date"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-datepicker
                    v-model="data.start_date"
                    placeholder="Select a date..."
                    :min-date="minDate"
                    :max-date="maxDate"
                  ></b-datepicker>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="end_date"
                v-slot="{ errors }"
                rules="required|date_higher_than:@start_date"
              >
                <b-field
                  label="End Date"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-datepicker
                    v-model="data.end_date"
                    placeholder="Select a date..."
                    :min-date="data.start_date"
                    :max-date="maxDate"
                  ></b-datepicker>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12" v-if="data.image == null">
              <b-field label="Upload File">
                <b-upload
                  v-model="data.image"
                  accept="image/*"
                  drag-drop
                  expanded
                  m
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="image" size="is-large"></b-icon>
                      </p>
                      <p>
                        Drag image here or
                        <span class="has-red-text">Browse</span>
                      </p>
                    </div>
                  </section>
                </b-upload>
              </b-field>
            </div>
            <div class="column is-4" v-if="data.image != null">
              <label class="label">File to upload</label>
            </div>
            <div class="column is-4" v-if="data.image != null">
              <div class="tag is-primary">
                <span class="file-tag">
                  {{ data.image.name }}
                </span>

                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteDropFile()"
                ></button>
              </div>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="request reason"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Request Reason"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    type="textarea"
                    v-model="data.request_reason"
                    placeholder="Please write your message"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                expanded
                :loading="isLoading"
                type="is-primary"
              >
                Request Time Off
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </r-page-modal>
</template>
<script>
import ApiService from '@/services/common/api.service.js'
import { mapActions, mapGetters } from 'vuex'
import { statusRequest } from '@/constant'
import { showToast } from '@/services/util'
import moment from 'moment'

export default {
  data() {
    const today = new Date()
    return {
      data: {
        id: '',
        user: null,
        timeoff: null,
        start_date: null,
        end_date: null,
        image: null,
        status: null,
        request_reason: '',
        decline_reason: '',
      },
      statusRequest: statusRequest,
      userList: [],
      timeoffList: [],
      isLoading: false,
      isFetchingUser: false,
      isFetchingTimeoff: false,
      minDate: new Date(),
      maxDate: new Date(
        today.getFullYear() + 100,
        today.getMonth(),
        today.getDate()
      ),
    }
  },
  async mounted() {
    await this.$store.dispatch('user/loadUserFromToken')
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
    }),
  },
  created() {
    this.getFilterTimeoff()
  },
  methods: {
    ...mapActions({
      saveTimeoff: 'timeOffModule/saveTimeoff',
    }),

    // Filter timeoff
    getFilterTimeoff() {
      this.isFetchingTimeoff = true

      ApiService.get('api/space-roketin/time-off/get-filter-time-off')
        .then((response) => {
          this.timeoffList = []
          response.data.forEach((item) => this.timeoffList.push(item))
        })
        .catch(() => {
          this.timeoffList = []
        })
        .finally(() => {
          this.isFetchingTimeoff = false
        })
    },

    openFileURL(fileUrl) {
      window.open(`${this.backendUrl}${fileUrl}`)
    },
    deleteDropFile() {
      this.data.image = null
    },

    async submit() {
      this.isLoading = true
      let form = new FormData()

      try {
        if (this.currentUser !== null) {
          form.append('user_id', this.currentUser.id)
          form.append('status', 'waiting')
        }

        if (this.data.image) {
          form.append('images[]', this.data.image)
        }

        form.append(
          'start_date',
          moment(this.data.start_date).format('YYYY-MM-DD')
        )
        form.append('end_date', moment(this.data.end_date).format('YYYY-MM-DD'))
        form.append('timeoff_type_id', this.data.timeoff)
        form.append('request_reason', this.data.request_reason)

        await this.saveTimeoff(form)
        showToast('Add Success', 'is-success', 'is-bottom')
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-bottom')
      }

      this.isLoading = false

      this.resetForm()
      this.$emit('close-modal')
    },

    resetForm() {
      this.data = {
        ...{
          id: '',
          user: null,
          timeoff: null,
          startDate: null,
          endDate: null,
          status: null,
          request_reason: '',
          decline_reason: '',
          images: [],
        },
      }
    },
  },
}
</script>
